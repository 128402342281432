import React, { useState } from "react";
import { useEffect } from "react";
import logo from "../../../assets/AZBEACONS PNG.png";

export const Navbar = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [navbarColor, setNavbarColor] = useState(false);

  const handleViewNavbar = () => {
    setNavbarOpen(!navbarOpen);
  };

  const changeBackground = () => {
    if (window.scrollY >= 600) {
      setNavbarColor(true);
    } else {
      setNavbarColor(false);
    }
  };

  useEffect(() => {
    changeBackground();
    window.addEventListener("scroll", changeBackground);
  });

  const navbarClass = navbarOpen ? "navbar open" : "navbar hidden md:block";
  const navbarChangeColor = navbarColor ? "bg-red-600" : "hidden md:block";

  return (
    <>
      <div className="flex justify-between bg-red-600 md:bg-transparent md:hidden">
        <div>
          <a href="/#" className="btn btn-ghost normal-case text-xl">
            <img className="w-44 md:w-40" src={logo} alt="Logo del sitio" />
          </a>
        </div>
        <div className="hidden">
          <button
            onClick={handleViewNavbar}
            className="navbar-toggle md:hidden"
          >
            <span className="material-symbols-outlined m-3">menu</span>
          </button>
        </div>
      </div>

      <div className={`${navbarClass} ${navbarChangeColor} fixed`}>
        <div className="navbar bg-base-100 bg-transparent">
          <div className="flex-1 hidden md:block">
            <button className="btn btn-ghost normal-case text-xl">
              <img className="w-40 md:w-40" src={logo} alt="Logo del sitio" />
            </button>
          </div>
          <div className="flex-none ">
            <ul className="menu md:menu-horizontal px-1">
              <li>
                <a href="#whatIs" className="text-white font-bold active:bg-red-400">
                  ¿Qué es?
                </a>
              </li>

              <li>
                <a href="#howDoesItWork" className="text-white font-bold active:bg-red-400">
                  ¿Cómo funciona?
                </a>
              </li>

              <li>
                <a href="#features" className="text-white font-bold active:bg-red-400">
                  Características
                </a>
              </li>

              <li>
                <a href="#technicalDetails" className="text-white font-bold active:bg-red-400">
                  Detalles técnicos
                </a>
              </li>

              <li>
                <a href="#downloadApp" className="text-white font-bold active:bg-red-400">
                  Cómo y dónde descargar la aplicación
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
