import { Footer } from "./components/ui/footer/Footer";
import { Home } from "./components/ui/home/Home";
import { Navbar } from "./components/ui/navbar/Navbar";

function App() {
  return (
    <div className="">
      <Navbar />
      <Home />
      <Footer />
    </div>
  );
}

export default App;
