import React from "react";
import banner from "../../../assets/1-BEACONS.jpg";
import whatIs from "../../../assets/2-QUE-ES-modificada.jpg";
import howDoesItWork from "../../../assets/3-COMO-FUNCIONA-N2.jpg";
import features from "../../../assets/4-CARACTERISTICA-N2.jpg";
import technicalDetails from "../../../assets/5-DETALLES-TECNICOS-N2.jpg";

export const Home = () => {
  return (
    <>
      <div className="bg-banner">
        <img src={banner} alt="Presentación de la página" />
      </div>

      <div className="bg-whatIs" id="whatIs">
        <img src={whatIs} alt="¿Qué es Azbeacons?" />
      </div>

      <div className="bg-howDoesItWork" id="howDoesItWork">
        <img src={howDoesItWork} alt="Cómo funciona el Azbeacons" />
      </div>

      <div className="bg-features" id="features">
        <img src={features} alt="Características del Azbeacons" />
      </div>

      <div className="bg-technicalDetails" id="technicalDetails">
        <img
          src={technicalDetails}
          alt="Detalles técnicos del dispositivo Azbeacons"
        />
      </div>
    </>
  );
};
