import React from "react";
import footer from "../../../assets/FINAL-AZBEACONS_editado-1.jpg";
import logoAppStore from "../../../assets/Download_on_the_App_Store_Badge_ESMX_RGB_blk_100217.svg";

export const Footer = () => {
  return (
    <footer className="footer bg-base-200 text-base-content" id="downloadApp">
      <div className="relative bg-downloadApp">
        <img className="" src={footer} alt="Descargar la aplicación" />

        <div className="absolute flex items-center justify-self-center top-14 md:top-72">
          <a
            className="mr-2"
            href="https://play.google.com/store/apps/details?id=com.azlogica.azbeacon&hl=es_CO&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          >
            <img
              className="w-[9rem] left-52 md:w-[22rem]"
              alt="Disponible en Google Play"
              src="https://play.google.com/intl/es-419/badges/static/images/badges/es-419_badge_web_generic.png"
            />
          </a>

          <a
            className="ml-2"
            href="https://apps.apple.com/co/app/azbeacons/id1192734987"
          >
            <img
              className="w-[8rem] md:w-[20rem]"
              alt="Disponible en App Store"
              src={logoAppStore}
            />
          </a>
        </div>
      </div>
    </footer>
  );
};
